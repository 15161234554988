import React from "react"
import styled, { css } from "styled-components"

const EmptySpace = styled.div`
  grid-column: 2 / span 12;
  grid-row: 0;
  height: 0;
  background-color: ${props => props.theme.colors.background};
  ${props =>
    props.four &&
    props.big &&
    css`
      grid-row: 4;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 4;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        grid-row: 4;
        height: 5rem;
      }
    `}
  ${props =>
    props.six &&
    props.big &&
    css`
      grid-row: 6;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 6;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        grid-row: 6;
        height: 5rem;
      }
    `}
  ${props =>
    props.eight &&
    props.big &&
    css`
      grid-row: 8;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 8;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        grid-row: 8;
        height: 5rem;
      }
    `}
  ${props =>
    props.doubleeight &&
    props.big &&
    css`
      grid-row: 8;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        height: 10rem;
      }

      @media screen and (max-width: 63rem) {
        grid-row: 9;
        height: 5rem;
      }
    `}
  ${props =>
    props.nine &&
    props.big &&
    css`
      grid-row: 9;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 10;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        grid-row: 11;
        height: 5rem;
      }
    `}

  ${props =>
          props.normalTen &&
          props.big &&
          css`
      grid-row: 10;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 11;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        height: 5rem;
      }
    `}
  ${props =>
    props.ten &&
    props.big &&
    css`
      grid-row: 10;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 11;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        grid-row: 10;
        height: 5rem;
      }
    `}
  ${props =>
    props.bpTen &&
    props.big &&
    css`
      grid-row: 10;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 11;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        grid-row: 14;
        height: 5rem;
      }
    `}
  ${props =>
    props.hdTen &&
    props.big &&
    css`
      grid-row: 10;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        grid-row: 12;
        height: 5rem;
      }
    `}
  ${props =>
    props.eleven &&
    props.big &&
    css`
      grid-row: 11;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 11;
        height: 10rem;
      }

      @media screen and (max-width: 63rem) {
        grid-column: 2 / span 6;
        grid-row: 13;
        height: 5rem;
      }
    `}
  ${props =>
    props.rEleven &&
    props.big &&
    css`
      grid-row: 11;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 11;
        height: 10rem;
      }

      @media screen and (max-width: 63rem) {
        grid-column: 2 / span 6;
        height: 5rem;
      }
    `}
  ${props =>
    props.mEleven &&
    props.big &&
    css`
      grid-row: 11;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 12;
        height: 10rem;
      }

      @media screen and (max-width: 63rem) {
        grid-column: 2 / span 6;
        grid-row: 15;
        height: 5rem;
      }
    `}
  ${props =>
    props.twelve &&
    props.big &&
    css`
      grid-row: 12;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 13;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        height: 5rem;
      }
    `}
  ${props =>
    props.thirteen &&
    props.big &&
    css`
      grid-row: 14;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 15;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        grid-row: 18;
        height: 5rem;
      }
    `}
  ${props =>
    props.hdThirteen &&
    props.big &&
    css`
      grid-row: 13;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        height: 10rem;
      }
      @media screen and (max-width: 63rem) {
        grid-row: 14;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        height: 5rem;
      }
    `}
  ${props =>
    props.bpThirteen &&
    props.big &&
    css`
      grid-row: 16;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 15;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        grid-row: 20;
        height: 5rem;
      }
    `}
  ${props =>
    props.fourteen &&
    props.big &&
    css`
      grid-row: 14;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 19;
        height: 10rem;
      }

      @media screen and (max-width: 63rem) {
        grid-column: 2 / span 6;
        height: 5rem;
      }
    `}
  ${props =>
    props.fifteen &&
    props.big &&
    css`
      grid-row: 16;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 17;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        grid-row: 20;
        height: 5rem;
      }
    `}
  ${props =>
          props.sixteen &&
          props.big &&
          css`
      grid-row: 16;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 17;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        grid-row: 17;
        height: 5rem;
      }
    `}
  ${props =>
          props.rSixteen &&
          props.big &&
          css`
      grid-row: 16;
      height: 10rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 17;
        height: 5rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        grid-row: 17;
        height: 2.5rem;
      }
    `}
  ${props =>
          props.mSixteen &&
          props.big &&
          css`
      grid-row: 16;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 21;
        height: 10rem;
      }

      @media screen and (max-width: 63rem) {
        grid-column: 2 / span 6;
        grid-row: 22;
        height: 5rem;
      }
    `}
  ${props =>
          props.seventeen &&
          props.big &&
          css`
      grid-row: 17;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        height: 5rem;
        grid-row: 20;
      }
    `}
  ${props =>
          props.nineteen &&
          props.big &&
          css`
      grid-row: 19;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        height: 10rem;
      }
        @media screen and (max-width: 63rem) {
        grid-column: 2 / span 6;
        height: 10rem;
        grid-row: 23;
        }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        height: 5rem;
        grid-row: 23;
      }
    `}
  ${props =>
          props.dhNineteen &&
          props.big &&
          css`
      grid-row: 19;
      height: 10rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        grid-row: 20;
        height: 5rem;
      }

      @media screen and (max-width: 37rem) {
        height: 2.5rem;
      }
    `}
  ${props =>
          props.twentythree &&
          props.big &&
          css`
      grid-row: 23;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        height: 10rem;
      }
        @media screen and (max-width: 63rem) {
        grid-row: 27;
        height: 10rem;
        }

      @media screen and (max-width: 37rem) {
        height: 5rem;
      }
    `}
  ${props =>
          props.hdTwentytwo &&
          props.big &&
          css`
      grid-row: 22;
      height: 10rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        height: 5rem;
        grid-row: 23;
      }

      @media screen and (max-width: 37rem) {
        height: 2.5rem;
      }
    `}
  ${props =>
          props.twentyfive &&
          props.big &&
          css`
      grid-row: 25;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        height: 10rem;
      }
        @media screen and (max-width: 63rem) {
          grid-row: 29;
        }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        height: 5rem;
        grid-row: 29;
      }
    `}
  ${props =>
          props.iTwentyfive &&
          props.big &&
          css`
      grid-row: 25;
      height: 23rem;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        height: 10rem;
      }

      @media screen and (max-width: 37rem) {
        grid-column: 2 / span 6;
        height: 5rem;
        grid-row: 30;
      }
    `}
  ${props =>
          props.twentyseven &&
          css`
      grid-row: 27;
      height: 0;

      @media screen and (max-width: 90rem) {
        grid-column: 2 / span 6;
        height: 5rem;
      }

      @media screen and (max-width: 63rem) {
        grid-column: 2 / span 6;
        height: 5rem;
        grid-row: 32;
      }
    `}
`

export const Space = ({
  big,
  four,
  six,
  eight,
  doubleeight,
  nine,
  normalTen,
  ten,
  bpTen,
  hdTen,
  eleven,
  mEleven,
  rEleven,
  twelve,
  thirteen,
  hdThirteen,
  fourteen,
  bpThirteen,
  fifteen,
  sixteen,
  rSixteen,
  mSixteen,
  seventeen,
  nineteen,
  dhNineteen,
  hdTwentytwo,
  twentythree,
  twentyfive,
  iTwentyfive,
  twentyseven,
  children,
}) => {
  return (
    <EmptySpace
      big={big}
      four={four}
      six={six}
      eight={eight}
      doubleeight={doubleeight}
      nine={nine}
      normalTen={normalTen}
      ten={ten}
      hdTen={hdTen}
      bpTen={bpTen}
      eleven={eleven}
      mEleven={mEleven}
      rEleven={rEleven}
      twelve={twelve}
      thirteen={thirteen}
      hdThirteen={hdThirteen}
      bpThirteen={bpThirteen}
      fourteen={fourteen}
      fifteen={fifteen}
      sixteen={sixteen}
      rSixteen={rSixteen}
      mSixteen={mSixteen}
      seventeen={seventeen}
      nineteen={nineteen}
      hdTwentytwo={hdTwentytwo}
      dhNineteen={dhNineteen}
      twentythree={twentythree}
      twentyfive={twentyfive}
      iTwentyfive={iTwentyfive}
      twentyseven={twentyseven}
    >
      {children}
    </EmptySpace>
  )
}

export default Space
