import React from "react"
import styled, { css } from "styled-components"

const StyledButton = styled.button`
  padding: 1.5rem 1.5rem;
  font-size: 1em;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.cards};
  text-transform: uppercase;
  outline: none;
  border-color: ${props => props.theme.colors.shapes};
  margin: 30px 16px 0 0;
  cursor: pointer;
  width: 15.2vw;
  height: 70px;
  font-family: "Archivo Black", sans-serif;

  @media screen and (max-width: 90rem) {
    padding: 1rem;
    width: 200px;
    height: 70px;
  }
  @media screen and (max-width: 37rem) {
    width: 159px;
    height: 70px;
    margin-top: 15px;
    font-size: 0.9em;
  }

  ${({ primaryGreen, alignLeft }) =>
    primaryGreen &&
    alignLeft &&
    css`
      background-color: ${props => props.theme.colors.green};
      border-radius: 0 0 37px 0;
      text-align: left;

      &:hover {
        z-index: -1;
        background-color: #9CE5C3;
        color: ${props => props.theme.colors.cards};
      }
    `}

  ${({ primaryGreen, alignRight }) =>
          primaryGreen &&
          alignRight &&
          css`
      background-color: ${props => props.theme.colors.green};
      border-radius: 37px 0 0 0;
      text-align: right;
      margin: 0;

      &:hover {
        z-index: -1;
        background-color: #9CE5C3;
        color: ${props => props.theme.colors.cards};
      }
    `}

  ${({ secundaryGreen, alignRight }) =>
    secundaryGreen &&
    alignRight &&
    css`
      background-color: ${props => props.theme.colors.shapes};
      color: ${props => props.theme.colors.green};
      border-radius: 37px 0 0 0;
      border-width: 4px;
      border-color: ${props => props.theme.colors.green};
      text-align: right;
      margin-right: 0;

      &:hover {
        z-index: -1;
        background-color: #080809;
        color: ${props => props.theme.colors.primaryGreen};
      }
    `}


  ${({ primaryBlue, alignLeft }) =>
          primaryBlue &&
          alignLeft &&
          css`
      background-color: ${props => props.theme.colors.blue};
      border-radius: 0 0 37px 0;
      text-align: left;

      &:hover {
        z-index: -1;
        background-color: #8FC8F1;
        color: ${props => props.theme.colors.cards};
      }
    `}

  ${({ secundaryBlue, alignRight }) =>
          secundaryBlue &&
          alignRight &&
          css`
      background-color: ${props => props.theme.colors.shapes};
      color: ${props => props.theme.colors.blue};
      border-radius: 37px 0 0 0;
      border-width: 4px;
      border-color: ${props => props.theme.colors.blue};
      text-align: right;
      margin-right: 0;

      &:hover {
        z-index: -1;
        background-color: #080809;
      }
    `}

  ${({ primaryViolet, alignLeft }) =>
    primaryViolet &&
    alignLeft &&
    css`
      background-color: ${props => props.theme.colors.violet};
      border-radius: 0 0 37px 0;
      text-align: left;

      &:hover {
        z-index: -1;
        background-color: #B9B8FF;
        color: ${props => props.theme.colors.cards};
      }
    `}

  ${({ primaryViolet, alignRight }) =>
    primaryViolet &&
    alignRight &&
    css`
      background-color: ${props => props.theme.colors.violet};
      border-radius: 37px 0 0 0;
      text-align: right;
      margin: 0;

      &:hover {
        z-index: -1;
        background-color: #B9B8FF;
        color: ${props => props.theme.colors.cards};
      }
    `}

  ${({ secundaryViolet, alignRight }) =>
    secundaryViolet &&
    alignRight &&
    css`
      background-color: ${props => props.theme.colors.shapes};
      color: ${props => props.theme.colors.violet};
      border-radius: 37px 0 0 0;
      border-color: ${props => props.theme.colors.violet};
      border-width: 4px;
      text-align: right;
      margin-right: 0;

      &:hover {
        z-index: -1;
        background-color: #080809;
      }
    `}

  ${({ primaryPink, alignLeft }) =>
          primaryPink &&
          alignLeft &&
          css`
      background-color: ${props => props.theme.colors.pink};
      border-radius: 0 0 37px 0;
      text-align: left;

      &:hover {
        z-index: -1;
        background-color: #E6A9DF;
        color: ${props => props.theme.colors.cards};
      }
    `}
  ${({ primaryPink, alignRight }) =>
          primaryPink &&
          alignRight &&
          css`
      background-color: ${props => props.theme.colors.pink};
      border-radius: 37px 0 0 0;
      text-align: right;
            margin-right: 0;

      &:hover {
        z-index: -1;
        background-color: #E6A9DF;
        color: ${props => props.theme.colors.cards};
      }
    `}

  ${({ secundaryPink, alignRight }) =>
          secundaryPink &&
          alignRight &&
          css`
      background-color: ${props => props.theme.colors.shapes};
      color: ${props => props.theme.colors.pink};
      border-radius: 37px 0 0 0;
      border-color: ${props => props.theme.colors.pink};
      border-width: 4px;
      text-align: right;
      margin-right: 0;

      &:hover {
        z-index: -1;
        background-color: #080809;
      }
    `}

  ${({ catPrimaryGreen, alignLeft }) =>
          catPrimaryGreen &&
          alignLeft &&
          css`
      background-color: ${props => props.theme.colors.green};
      border-radius: 0 0 37px 0;
      text-align: left;

      &:hover {
        z-index: -1;
        background-color: #9CE5C3;
        color: ${props => props.theme.colors.cards};
      }
    `}
  ${({ backHome, alignLeft }) =>
          backHome &&
          alignLeft &&
          css`
      background-color: ${props => props.theme.colors.green};
      border-radius: 0 0 37px 0;
      text-align: left; 
      margin-right: 0;

      &:hover {
        z-index: -1;
        border-color: ${props => props.theme.colors.green};
        background-color: ${props => props.theme.colors.green};
        color: ${props => props.theme.colors.cards};
      }
      @media screen and (max-width: 110rem) {
        padding: 1rem;
      }
    `}

  ${({ catPrimaryBlue, alignLeft }) =>
          catPrimaryBlue &&
          alignLeft &&
          css`
      background-color: ${props => props.theme.colors.blue};
      border-radius: 0 0 37px 0;
      text-align: left;

      &:hover {
        z-index: -1;
        background-color: #8FC8F1;
        color: ${props => props.theme.colors.cards};
      }
    `}
  ${({ catPrimaryViolet, alignLeft }) =>
          catPrimaryViolet &&
          alignLeft &&
          css`
      background-color: ${props => props.theme.colors.violet};
      border-radius: 0 0 37px 0;
      text-align: left;

      &:hover {
        z-index: -1;
        background-color: #B9B8FF;
        color: ${props => props.theme.colors.cards};
      }
    `}
  ${({ catPrimaryPink, alignLeft }) =>
          catPrimaryPink &&
          alignLeft &&
          css`
      background-color: ${props => props.theme.colors.pink};
      border-radius: 0 0 37px 0;
      text-align: left;

      &:hover {
        z-index: -1;
        background-color: #E6A9DF;
        color: ${props => props.theme.colors.cards};
      }
    `}
`

export const Button = ({
  primaryGreen,
  secundaryGreen,
  primaryBlue,
  secundaryBlue,
  primaryViolet,
  secundaryViolet,
  primaryPink,
  secundaryPink,
  catPrimaryGreen,
  catPrimaryBlue,
  catPrimaryViolet,
  catPrimaryPink,
  backHome,
  alignLeft,
  alignRight,
  children,
}) => {
  return (
    <StyledButton
      primaryGreen={primaryGreen}
      secundaryGreen={secundaryGreen}
      primaryBlue={primaryBlue}
      secundaryBlue={secundaryBlue}
      primaryViolet={primaryViolet}
      secundaryViolet={secundaryViolet}
      primaryPink={primaryPink}
      secundaryPink={secundaryPink}
      catPrimaryGreen={catPrimaryGreen}
      catPrimaryBlue={catPrimaryBlue}
      catPrimaryViolet={catPrimaryViolet}
      catPrimaryPink={catPrimaryPink}
      backHome={backHome}
      alignLeft={alignLeft}
      alignRight={alignRight}
    >
      {children}
    </StyledButton>
  )
}

export default Button
