import React from "react"
import styled, { css } from "styled-components"

const H2ColorHighlight = styled.line`
  color: ${props => props.theme.colors.primary};


  ${props =>
          props.color === "boldPrimary" &&
          css`
      color: ${props => props.theme.colors.primary};
      font-family: "Archivo Black", sans-serif;
    `}
  ${props =>
          props.color === "boldGrey" &&
          css`
      color: ${props => props.theme.colors.secundaryDark};
      font-family: "Archivo Black", sans-serif;
    `}
  ${props =>
    props.color === "green" &&
    css`
      color: ${props => props.theme.colors.green};
    `}
  ${props =>
    props.color === "boldGreen" &&
    css`
      color: ${props => props.theme.colors.green};
      font-family: "Archivo Black", sans-serif;
    `}
  ${props =>
    props.color === "turquoise" &&
    css`
      color: ${props => props.theme.colors.turquoise};
    `}
  ${props =>
    props.color === "boldTurquoise" &&
    css`
      color: ${props => props.theme.colors.turquoise};
      font-family: "Archivo Black", sans-serif;
    `}
  ${props =>
    props.color === "blue" &&
    css`
      color: ${props => props.theme.colors.blue};
    `}
  ${props =>
    props.color === "boldBlue" &&
    css`
      color: ${props => props.theme.colors.blue};
      font-family: "Archivo Black", sans-serif;
    `}
  ${props =>
    props.color === "violet" &&
    css`
      color: ${props => props.theme.colors.violet};
    `}
  ${props =>
    props.color === "boldViolet" &&
    css`
      color: ${props => props.theme.colors.violet};
      font-family: "Archivo Black", sans-serif;
    `}
  ${props =>
          props.useHover && props.colorHoverValue === "violet" &&
    css`
      &:hover {
        color: #cdccff;
      }
    `}
  ${props =>
    props.color === "purple" &&
    css`
      color: ${props => props.theme.colors.purple};
    `}
  ${props =>
    props.color === "boldPurple" &&
    css`
      color: ${props => props.theme.colors.purple};
      font-family: "Archivo Black", sans-serif;
    `}
  ${props =>
          props.useHover && props.colorHoverValue === "purple" &&
    css`
      &:hover {
        color: #e2c2ff;
      }
    `}
  ${props =>
    props.color === "pink" &&
    css`
      color: ${props => props.theme.colors.pink};
    `}
  ${props =>
    props.color === "boldPink" &&
    css`
      color: ${props => props.theme.colors.pink};
      font-family: "Archivo Black", sans-serif;
    `}
  ${props =>
          props.useHover && props.colorHoverValue === "pink" &&
    css`
      &:hover {
        color: #edc1e8;
      }
    `}
  ${props =>
    props.color === "secundaryDark" &&
    css`
      color: ${props => props.theme.colors.secundaryDark};
      font-family: "Archivo Regular", sans-serif;
      font-size: 0.4em;
    `}
  ${props =>
    props.color === "smallSecundaryDark" &&
    css`
      color: ${props => props.theme.colors.secundaryDark};
      font-family: "Archivo Regular", sans-serif;
      font-size: 0.8em;
    `}
  ${props =>
    props.color === "grey" &&
    css`
      color: ${props => props.theme.colors.secundaryLight};
    `}
  ${props =>
    props.color === "tertiary" &&
    css`
      color: ${props => props.theme.colors.tertiary};
    `}
  ${props =>
    props.color === "shapes" &&
    css`
      color: ${props => props.theme.colors.shapes};
    `}
`

export const TextHighlight = ({ show=true, colorValue, useHover= false, colorHoverValue, children }) => {
  if(show){
    return <H2ColorHighlight useHover={useHover} colorHoverValue={colorHoverValue} color={colorValue}>{children}</H2ColorHighlight>
  }else{
    return null;
  }
}

export default TextHighlight
